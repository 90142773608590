<template>
  <div class="pokemon-container">
    <img class="pokemon-hidden" :src= imgSrc alt="">
    <img v-if="pokemonShow" class="fade-in"
         :src= imgSrc alt="">
  </div>
</template>
<script>

export default {

  props:{
    pokemonId:{
      type: Number,
      require: true,
    },
    pokemonShow:{
      type:Boolean,
      require: true,
      default: false
    }
  },

  computed:{
    imgSrc(){
      return `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/dream-world/${this.pokemonId}.svg`
    }
  }

}

</script>
<style scoped>
.pokemon-container {
  height: 200px;
}

img {
  height: 200px;
  position: absolute;
  right: 32%;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

.hidden-pokemon {
  filter: brightness(0);
}

.pokemon-hidden {

  filter: brightness(0);

}

img {

  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-drag: none;
}
</style>