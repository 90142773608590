<template>
  <h1 v-if="!pokemon">Espere por favor....</h1>
  <div v-else>
    <h1>¿ Quien es este Pokemon</h1>
    <pokemon-picture
        :pokemonId="pokemon.id"
        :pokemonShow="showPokemon">

    </pokemon-picture>

    <pokemon-options
        :pokemons="pokemonArr"
        @selection=" checkAnswer"
    ></pokemon-options>

    <h2>{{message}}</h2>

    <button @click="newGame">Nuevo Juego</button>

  </div>
</template>
<script>

import PokemonPicture from "@/components/PokemonPicture.vue";
import PokemonOptions from "@/components/PokemonOptions.vue";

import getPokemonOptions from "@/helpers/getPokemoOptions.js"

console.log(getPokemonOptions())

export default {
  components: {
    PokemonOptions,
    PokemonPicture,
  },
  data() {
    return {
      pokemonArr: [],
      pokemon: null,
      showPokemon: false,
      showAnswer: false,
      message:''
    }
  },
  methods: {
    async mixPokemonsArray() {
      this.pokemonArr = await getPokemonOptions()
      const rndIn = Math.floor(Math.random() * 4)
      this.pokemon = this.pokemonArr[rndIn]
    },

    checkAnswer(pokemonId){
      if(pokemonId === this.pokemon.id){
        this.showPokemon= true
        this.message = `Correcto!!! es  ${this.pokemon.name}`
      }else{
        this.message =`Oppps el correcto es ${this.pokemon.name}`
      }
    },

    newGame(){
      this.mixPokemonsArray()
    }
  },
  mounted() {
    this.mixPokemonsArray()
  }
}

</script>
